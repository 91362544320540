import Base = require("Everlaw/Base");
import DatabaseFieldValue = require("Everlaw/DatabaseField/DatabaseFieldValue");
import { AdminSuspensionState } from "Everlaw/AdminSuspension/Constants";
import { OrganizationId } from "Everlaw/MinimalOrganization";
import { Organization } from "Everlaw/Organization";
import { DatabaseSuspensionState } from "Everlaw/ScheduledDatabaseSuspensionJob";

class Database extends Base.SecuredObject {
    get className() {
        return "Database";
    }
    override id: number;
    parcel: Parcel;
    name: string;
    owningOrgId: OrganizationId;
    orgAdminAccess: boolean;
    deleted = false;
    everlawDemo = false;
    databaseFieldValues: { [fieldId: number]: DatabaseFieldValue };
    context = Database.Context.EVERLAW;
    adminSuspensionState: string;
    databaseSuspensionState: string;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.databaseFieldValues = {};
        if (params.databaseFieldValues) {
            Object.keys(params.databaseFieldValues).forEach((fieldId) => {
                this.databaseFieldValues[Number(fieldId)] = Base.set(
                    DatabaseFieldValue,
                    params.databaseFieldValues[fieldId],
                );
            });
        }
    }
    override display() {
        return this.name;
    }
    // Return if the database itself is administrative suspended.
    isAdminSuspended(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.SUSPENDED;
    }
    // Return a boolean to decide if the database is impacted by an administrative suspension.
    // A database is under administrative suspension either itself or its parent org is in such state.
    isImpactedByAdminSuspension(): boolean {
        const org = Base.get(Organization, this.owningOrgId);
        return this.isAdminSuspended() || (org && org.isAdminSuspended());
    }
    // Return if the database itself is in admin suspension announcement state.
    inAdminSuspensionAnnouncement(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.ANNOUNCED;
    }
    // Return a boolean to decide if the database is impacted by an administrative suspension announcement.
    isImpactedByAdminSuspensionAnnouncement(): boolean {
        const org = Base.get(Organization, this.owningOrgId);
        return (
            !this.isImpactedByAdminSuspension()
            && (this.inAdminSuspensionAnnouncement()
                || (org && org.inAdminSuspensionAnnouncement()))
        );
    }
    // Return if the database is suspended by a user (e.g. requesting deletion)
    isSuspended(): boolean {
        return this.databaseSuspensionState === DatabaseSuspensionState.SUSPENDED;
    }
    isScheduledForSuspension(): boolean {
        return this.databaseSuspensionState === DatabaseSuspensionState.SCHEDULED;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Database {
    export function updateDatabaseSuspensionState(db: Database, dbSuspensionState: string) {
        db.databaseSuspensionState = dbSuspensionState;
        Base.update(Database, db);
    }

    export function displayFullId(dbId: number) {
        const vpc = JSP_PARAMS.Server.vpc;
        return (vpc === "STORYBUILDER" ? "SB" : vpc) + " DB " + dbId.toString();
    }

    export function displayOrUnknown(dbId: number) {
        const db: Database = Base.get(Database, dbId);
        return db ? db.display() : "Unknown database";
    }

    export function dbSelectorValues(db: Database) {
        return [Database.displayFullId(db.id), db.name];
    }

    export function dbSelectorStyle() {
        // Id div width should be $db-id-width (from _upload.scss) - 4px
        // Width of the name div is 0 because when it's too long, it gets replaced by ellipses
        return ["width: 126px;", "width: 0;"];
    }

    export enum Context {
        EVERLAW = "EVERLAW",
        SBFREE = "SBFREE",
        ECA = "ECA",
    }
}

export = Database;
