import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import DatabaseField = require("Everlaw/DatabaseField/DatabaseField");
import DateUtil = require("Everlaw/DateUtil");
import DateTimePrecision = require("Everlaw/DateTimePrecision");
import Is = require("Everlaw/Core/Is");
import type { DateTime } from "Everlaw/Type";

class DatabaseFieldValue extends Base.Object {
    get className() {
        return "DatabaseFieldValue";
    }
    fieldId: DatabaseField.Id;
    databaseId: number;
    json: DatabaseFieldValue.ValueJson;
    constructor(params: DatabaseFieldValue.Params) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: unknown) {
        Object.assign(this, params);
    }
    override display() {
        const field = this.getField();
        if (field.isSingleOrMultiSelect()) {
            const optionIdsSet = new Set();
            Arr.wrap(this.json).forEach((optId) => optionIdsSet.add(optId));
            return (<DatabaseField.SelectJson>field.json).options
                .filter((option) => optionIdsSet.has(option.id))
                .map((option) => option.text)
                .join(", ");
        }
        if (field.type === DatabaseField.Type.DATE) {
            const dateTimeJson = this.json as DateTime;
            if (!Is.number(dateTimeJson?.lower)) {
                return "Unknown";
            }
            const hasTime: boolean =
                DateTimePrecision.hasHourPrecision(dateTimeJson?.precision)
                && DateTimePrecision.hasHourPrecision(
                    (<DatabaseField.DateTimeJson>field.json).precision,
                );
            const dateFormat = DateUtil.getRegionDefaultDateDisplayFormat();
            const dateDisplayFormat = DateUtil.MOMENT_JS_DATE_FORMAT[dateFormat];
            const timeDisplayFormat =
                DateUtil.MOMENT_JS_TIME_FORMAT[DateUtil.TimeDisplayFormat.TWELVE_HOUR];
            return hasTime
                ? DateUtil.displayDateTimeAsZoneWithFormat(
                      dateTimeJson.lower,
                      (dateTimeJson.timezone || "UTC") as DateUtil.TimezoneNO,
                      dateDisplayFormat + " " + timeDisplayFormat,
                  )
                : DateUtil.displayFullDateWithFormat(
                      DateUtil.asDate(dateTimeJson.lower),
                      dateFormat,
                      false,
                  );
        }
        return String(this.json);
    }
    // For multiselect, display only the first element
    shortDisplay() {
        const field = this.getField();
        if (field.type === DatabaseField.Type.MULTI_SELECT) {
            const optionIdsSet = new Set();
            Arr.wrap(this.json).forEach((optId) => optionIdsSet.add(optId));
            let firstOption: DatabaseField.Option | undefined = undefined;
            (<DatabaseField.SelectJson>field.json).options.some((option) => {
                if (optionIdsSet.has(option.id)) {
                    firstOption = option;
                    return true;
                }
                return false;
            });
            firstOption = firstOption as DatabaseField.Option | undefined;
            return firstOption ? firstOption.text : "";
        }
        return this.display();
    }
    getField() {
        return Base.get(DatabaseField, this.fieldId);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module DatabaseFieldValue {
    export type Id = number & Base.Id<"DatabaseFieldValue">;

    export type ValueJson =
        | string // text
        | number // number, single select
        | number[] // multi select
        | DateTime; // datetime

    export interface Params {
        fieldId: DatabaseField.Id;
        databaseId: number;
        json: DatabaseFieldValue.ValueJson;
    }
}

export = DatabaseFieldValue;
